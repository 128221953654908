
import { Vue, Component, PropSync } from 'vue-property-decorator'
import { Services } from 'tradingmate-modules/src/services'
// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import CKEditorVariablePlugin from '@/app_code/Plugins/CKEditorVariablePlugin'
import { Config } from 'tradingmate-modules/src/environment'

@Component({
  components: {
    ckeditor: CKEditor.component
  }
})
export default class RichTextEditor extends Vue {
  @PropSync('valueSync', { default: null })
  public value!: string | null;

  private credential = Services.CredentialManager.GetOAuthCredential()

  mounted (): void {
    //
  }

  public editorDisabled = false
  public editor = ClassicEditor
  editorConfig = {
    plugins: [
      EssentialsPlugin,
      BoldPlugin,
      ItalicPlugin,
      LinkPlugin,
      ParagraphPlugin,
      SimpleUploadAdapter,
      Image,
      ImageToolbar,
      ImageCaption,
      ImageStyle,
      ImageResize,
      LinkImage,
      ImageUploadPlugin,
      CKEditorVariablePlugin
    ],
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'toggleImageCaption',
        'imageTextAlternative'
      ]
    },
    toolbar: {
      items: [
        'bold',
        'italic',
        'link',
        'undo',
        'redo',
        '|',
        // 'linkImage',
        // 'imageUpload',
        'placeholder'
      ]
    },
    placeholderConfig: {
      types: [
        'Member.Id',
        'Member.FirstName',
        'Member.LastName',
        'Member.Email',
        'Member.TradingName',
        'Member.ExpirationDate',
        'Member.ProfileUrl',
        'Member.RenewalLink',
        'Member.MembershipPageLink',
        'Member.Regions',
        'Member.VerificationCode',
        'Member.Mobiles',
        'Member.Landlines',
        'Member.ABN',
        'Member.Categories',
        'Invoice.InvoiceNo',
        'Invoice.DueDate',
        'Invoice.Amount',
        'QuoteRequest.Email',
        'QuoteRequest.FullName',
        'QuoteRequest.CreateDate',
        'QuoteRequest.MatchedCount',
        'QuoteRequest.SeenCount',
        'QuoteRequest.ServiceRequested',
        'QuoteRequest.AdditionalInfo',
        'QuoteRequest.Zone',
        'QuoteRequest.Category',
        'QuoteRequest.ResolveSearch',
        'Website.Domain',
        'Website.Url',
        'ContactUs.Email',
        'ContactUs.FullName',
        'ContactUs.Message',
        'Public.AboutUsLink'
      ]
    },
    simpleUpload: {
      uploadUrl: Config.API.Base + '/files/email/template/image',
      // withCredentials: true,
      headers: {
        'X-CSRF-TOKEN': 'CSRF-Token',
        Authorization: `Bearer oauth:${this.credential?.Token}`
      }
    }
  }
}
